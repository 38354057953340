.displayGuessContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.displayGuessRow {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    width: 336px;
    margin: 2.5px 0;
}

.displayGuessBox {
    height: 58px;
    border: 2px solid #3a3a3c;
    text-align: center;
    padding: 2px;
    display: flex;
    align-items: center;
  justify-content: center;
  overflow: hidden;
    
}


.playerName {
    font-size: 13.28px;
    font-weight: 700;
    margin: 0;
}

.playerRegion {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.teamLogo{
    width: 100%;
    height: auto;
}

.flag{
    width: 100%;
    height: auto;
}

