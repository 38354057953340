.inputGuessContainer {
    
    display: flex;
    justify-content: center;
}

.description-container {
  display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.input-description {
  text-align: center;
  width: 336px;
}
 
 .guessInput {
    padding: 7px;
    border: 2px solid #818384;
    display: flex;
    justify-content: space-between;
    color: white;
    font-family: "Inter", sans-serif;
    width: 318px;
    margin: 0;
}

#guessInput {
    padding: 14px 18px;
    width: 100%;
    outline: 0;
}
.guessInput input {
    border: none;
    background: 0 0;
    color: white;
    font-size: 16px;
}

.btn--submit--guess {
    padding: 10px 18px;
    border-radius: 6px;
    border: none;
    background: #818384;
    cursor: pointer;
    color: white;
    font-weight: 700;
}

#guessInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3a3a3c;
    opacity: 1; /* Firefox */
  }
  
  #guessInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3a3a3c;
  }
  
  #guessInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3a3a3c;
  }

  .btn--submit--guess:disabled {
    background: #3a3a3c;
    cursor: not-allowed;
}