@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  body {
    margin: 0;
    background-color: #121213;
    color: white;
    font-family: "Inter", sans-serif;
    height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
  
  a:hover {
    color: #818384;
    cursor: pointer;
  }
  
  /* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .container {
      padding: 0 !important;
      width: 540px;
      margin: auto;
    }

    .main-heading {
        font-size: 37px !important;
        font-weight: 900;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .container {
      width: 720px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .container {
      width: 960px;
    }
  }
  
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .container {
      width: 1140px;
    }
  }
  
  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) {
    .container {
      width: 1320px;
    }
  }
  
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
  }

  .displayGuessSection {
      padding: 60px 0px 60px 0px;

  }

  